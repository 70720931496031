import React from "react"
import Style from "./ProductAddPage.module.scss"
import AddLayout from "../../layouts/client/add/AddLayout"
import { useHistory } from "react-router-dom"
import { RegisterOptions, FieldValues } from "react-hook-form"
import { ProviderUserProductService } from "../../providers/provider-user-product.service"
import HeaderFormComponent, { HeaderFormStep } from "../../components/header-form/HeaderFormComponent"
import FieldText from "../../atoms/field-text/FieldText"
import Button, { ButtonType } from "../../atoms/button/Button"
import FieldUpload from "../../atoms/field-upload/FieldUpload"
import FieldTextarea from "../../atoms/field-textarea/FieldTextarea"

enum FORM_CONTROL {
    Name = 'name',
    Image = 'image',
    NetQuantity = 'netQuantity',
    UnitType = 'unitType',
    Unit = 'unit',
    Description = 'description'
}

const AddForm = (props: any) => {
    const history = useHistory()
    const HeaderFormSteps: HeaderFormStep[] = [
        {
            name: 'AddNewProduct',
            label: 'Add new product'
        }
    ]

    return <React.Fragment>
        <div className={Style['container']}>
            <HeaderFormComponent active={HeaderFormSteps[0]} steps={HeaderFormSteps} />
            <p className={Style['sub-heading']}>Required fields are marked with an asterisk *</p>
            <form className={Style['form']} onSubmit={props.onFormSubmit}>
                <div className={Style['row']}>
                    <FieldText formContolName={props.formControlName(FORM_CONTROL.Name)} error={props.getErrorMessage(FORM_CONTROL.Name)} name={FORM_CONTROL.Name} label="Product name *" />
                    <FieldUpload />
                </div>
                <div className={Style['row']}>
                    <FieldText formContolName={props.formControlName(FORM_CONTROL.NetQuantity)} error={props.getErrorMessage(FORM_CONTROL.NetQuantity)} name={FORM_CONTROL.NetQuantity} label="Net quantity *" />
                    <div className={Style['sub-row']}>
                        <FieldText formContolName={props.formControlName(FORM_CONTROL.UnitType)} error={props.getErrorMessage(FORM_CONTROL.UnitType)} name={FORM_CONTROL.UnitType} label="Unit type *" />
                        <FieldText formContolName={props.formControlName(FORM_CONTROL.Unit)} error={props.getErrorMessage(FORM_CONTROL.Unit)} name={FORM_CONTROL.Unit} label="Unit *" />
                    </div>
                </div>
                <div className={Style['row']}>
                    <FieldTextarea formContolName={props.formControlName(FORM_CONTROL.Description)} error={props.getErrorMessage(FORM_CONTROL.Description)} name={FORM_CONTROL.Description} label="Description" />
                </div>
                <div className={Style['row-btn']}>
                    <Button handleClick={() => history.goBack()}>Cancel</Button>
                    <Button type={ButtonType.Submit}>Create</Button>
                </div>
            </form>
        </div>
    </React.Fragment>
}

const ProductAddPage = () => {
    const formControlNameOption = (control: string) => {
        let option: RegisterOptions = {}
        switch (control) {
            case FORM_CONTROL.Name: {
                option = {
                    required: { value: true, message: "Product Name Is Required" }
                }
                break
            }
            case FORM_CONTROL.Image: {
                option = {
                    required: { value: true, message: "Image Is Required" },
                }
                break
            }
            case FORM_CONTROL.NetQuantity: {
                option = {
                    required: { value: true, message: "Net Quantity Is Required" },
                }
                break
            }
            case FORM_CONTROL.UnitType: {
                option = {
                    required: { value: true, message: "Unit Type Is Required" },
                }
                break
            }
            case FORM_CONTROL.Unit: {
                option = {
                    required: { value: true, message: "Unit Is Required" },
                }
                break
            }
        }
        return option
    }

    const fInValid = (fValues: FieldValues) => { }

    const fValid = (fValues: FieldValues): object => {
        return {
            // name: fValues[FORM_CONTROL.ProductName],
            // phone: fValues[FORM_CONTROL.PhoneNumber],
            // email: fValues[FORM_CONTROL.Email],
            // address: fValues[FORM_CONTROL.Address],
            // pincode: fValues[FORM_CONTROL.Pincode],
            // city: fValues[FORM_CONTROL.City],
            // state: fValues[FORM_CONTROL.State]
        }
    }

    return <React.Fragment>
        <AddLayout formControlNameOption={formControlNameOption} fInValid={fInValid} fValid={fValid} add={ProviderUserProductService.add}>
            {(props: any) => AddForm({ ...props })}
        </AddLayout>
    </React.Fragment >
}

export default ProductAddPage
import React from 'react'
import Style from './LogoutModal.module.scss'
import IconProfile from '../../../assets/icons/icon_profile.svg'
import Setting from '../../../assets/icons/icon_setting.svg'
import Logout from '../../../assets/icons/icon_logout.svg'
import Button from '../../atoms/button/Button'

const LogoutModal = () => {
  return <React.Fragment>
    <div className={Style['container']}>
      <div className={Style['column']}>
        <div className={Style['row']}>
          <img src={IconProfile}></img>
          <p>Profile</p>
        </div>
        <div className={Style['row']}>
          <img src={Setting}></img>
          <p>Settings</p>
        </div>
      </div>
      <Button preIcon={Logout}>Logout from this device</Button>
    </div>
  </React.Fragment >
}

export default LogoutModal

import React from 'react'
import Style from './FieldCheckbox.module.scss'
import { UseFormRegisterReturn } from 'react-hook-form'

interface Props {
    formContolName: UseFormRegisterReturn<string>
    name: string
    label: string
}

const FieldCheckbox = (props: Props) => {
    return <React.Fragment>
        <div className={Style['container']}>
            <input type="checkbox" {...props.formContolName} id={props.name} className={Style['checkbox']} />
            <label className={Style['label']} htmlFor={props.name}>{props.label}</label>
        </div>
    </React.Fragment>
}

export default FieldCheckbox
import React from "react"
import Style from './FieldUpload.module.scss'
import IconUpload from '../../../assets/icons/icon_upload.svg'

const FieldUpload = () => {
    return <React.Fragment>
        <div className={Style['upload-container']}>
            <img src={IconUpload} alt="upload_icon" aria-label="Click to upload an image" className={Style['icon_upload']} />
            <label htmlFor="imageUpload">
                Upload image *
            </label>
            <input type="file" id="imageUpload" name="imageUpload" accept="image/*" />
        </div>
    </React.Fragment>
}

export default FieldUpload

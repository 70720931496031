import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import ClientGuardCanActivate from "../guards/ClientGuardCanActivate"
import ClientLayout from "../layouts/client/ClientLayout"
import BillAddPage from "../pages/bill-add/BillAddPage"
import BillAddPreviewPage from "../pages/bill-add-preview/BillAddPreviewPage"
import BillListPage from "../pages/bill-list/BillListPage"
import BillViewPage from "../pages/bill-view/BillViewPage"
import DashboardPage from "../pages/dashboard/DashboardPage"
import ProductAddPage from "../pages/product-add/ProductAddPage"
import ProductListPage from "../pages/product-list/ProductListPage"
import StoreAddPage from "../pages/store-add/StoreAddPage"
import StoreListPage from "../pages/store-list/StoreListPage"
import NotFound404Page from "../pages/not-found-404/NotFound404Page"

const ClientRouter = () => {
  const { path } = useRouteMatch()

  return <React.Fragment>
    <ClientGuardCanActivate >
      <ClientLayout>
        <Switch>
          <Redirect exact from={`${path}`} to={`${path}/dashboard`} />
          <Route path={`${path}/bill`}>
            <Switch>
              <Redirect exact from={`${path}/bill`} to={`${path}/bill/list`} />
              <Route path={`${path}/bill/add`}>
                <BillAddPage />
              </Route>
              <Route path={`${path}/bill/add-preview`}>
                <BillAddPreviewPage />
              </Route>
              <Route path={`${path}/bill/list`}>
                <BillListPage />
              </Route>
              <Route path={`${path}/bill/view`}>
                <BillViewPage />
              </Route>

              <Route path={'*'}>
                <NotFound404Page />
              </Route>
            </Switch>
          </Route>

          <Route path={`${path}/dashboard`}>
            <DashboardPage />
          </Route>

          <Route path={`${path}/product`}>
            <Switch>
              <Redirect exact from={`${path}/product`} to={`${path}/product/list`} />
              <Route path={`${path}/product/add`}>
                <ProductAddPage />
              </Route>
              <Route path={`${path}/product/list`}>
                <ProductListPage />
              </Route>
              <Route path={'*'}>
                <NotFound404Page />
              </Route>
            </Switch>
          </Route>

          <Route path={`${path}/store`}>
            <Switch>
              <Redirect exact from={`${path}/store`} to={`${path}/store/list`} />
              <Route path={`${path}/store/add`}>
                <StoreAddPage />
              </Route>
              <Route path={`${path}/store/list`}>
                <StoreListPage />
              </Route>
              <Route path={'*'}>
                <NotFound404Page />
              </Route>
            </Switch>
          </Route>

          <Route path={'*'}>
            <NotFound404Page />
          </Route>
        </Switch>
      </ClientLayout>
    </ClientGuardCanActivate>
  </React.Fragment>
}

export default ClientRouter


import React from 'react'
import Style from './ProductInfoCardComponent.module.scss'
import { TypeUserProduct } from '../../types/providers/type-user-product'
import Button from '../../atoms/button/Button'

interface Props {
    isVisible: boolean
    product: TypeUserProduct
    handleProduct: (product: TypeUserProduct, num: number) => void
}

const ProductInfoCardComponent = (props: Props) => {
    return <React.Fragment>
        <div className={Style['container']} style={{ display: props.isVisible ? 'flex' : 'none' }}>
            <div className={Style['short-icon']}>
                <p>GV</p>
            </div>
            <div className={Style['containt']}>
                <div className={Style['text-part']}>
                    <p className={Style['heading']}>{props.product.name}</p>
                    <p className={Style['sub-heading']}>{props.product.price}/- Only</p>
                </div>
                <Button handleClick={() => props.handleProduct(props.product, 1)}>Add</Button>
            </div>
        </div>
    </React.Fragment>
}
export default ProductInfoCardComponent

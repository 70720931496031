import React from 'react'
import Style from './HeaderStripComponent.module.scss'
import ImgLogoMomoPunch from '../../../assets/images/img_logo-momo_punch.svg'

const HeaderStripComponent = () => {
  return <React.Fragment>
    <div className={Style['container']}>
      <div className={Style['left']}>
        <div className={Style['active-store']}>
          <img className={Style['logo']} src={ImgLogoMomoPunch} alt="ImgLogoMomoPunch" />
          <div className={Style['text']}>
            <h2 className={Style['title']}>Momo Punch</h2>
            <p className={Style['address']}>Green valley, Swarg ashram road Swarg ashram road Swarg ashram road Swarg ashram road</p>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
}

export default HeaderStripComponent
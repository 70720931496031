import React, { useEffect, useState } from "react"
import Style from "./BillAddPage.module.scss"
import { TypeUserProduct } from "../../types/providers/type-user-product"
import { ProviderUserProductService } from "../../providers/provider-user-product.service"
import ListLayout from "../../layouts/client/list/ListLayout"
import Button from "../../atoms/button/Button"
import { useHistory, useLocation } from "react-router-dom"
import { v4 as uuidV4 } from 'uuid'
import ProductInfoCardComponent from "../../components/product-info-card/ProductInfoCardComponent"

interface TypeUserSelectedProduct extends TypeUserProduct {
    noOfItems: number
}

const BillAddPage = () => {
    const history = useHistory()
    const queryParams = new URLSearchParams(useLocation().search)
    const [availableProductList, setAvailableProductList] = useState<unknown[]>([])
    const [selectedProductList, setSelectedProductList] = useState<TypeUserSelectedProduct[]>([])

    useEffect(() => {
        if (queryParams.get('bill')) {
            const bill = JSON.parse(localStorage.getItem('bill-' + queryParams.get('bill')) as string)
            if (!bill) {
                window.alert('Link Expire Add New Bill')
                history.replace("/client/bill/add")
            } else {
                setSelectedProductList(bill['products'])
            }
        }
    }, [])

    const handleProduct = (product: TypeUserProduct, num: number) => {
        setSelectedProductList(perList => {
            const index = perList.findIndex(p => p.uuid === product.uuid)
            if (index !== -1) {
                if (perList[index].noOfItems <= 1 && num < 0) {
                    perList.splice(index, 1)
                } else {
                    perList[index].noOfItems += num
                }
            } else {
                perList.unshift({
                    ...product,
                    noOfItems: 1
                })
            }
            return [...perList]
        })
    }

    const isVisibleProduct = (product: TypeUserProduct): boolean => {
        return !selectedProductList.find(p => p.uuid === product.uuid)
    }

    const handleBillForPrint = () => {
        if (selectedProductList.length === 0) {
            window.alert('Select Product First')
            return
        }

        const payload = {
            uuid: queryParams.get('bill') ?? uuidV4(),
            products: selectedProductList
        }
        localStorage.setItem('bill-' + payload.uuid, JSON.stringify(payload))
        history.replace('/client/bill/add-preview?bill=' + payload.uuid)
    }


    return <React.Fragment>
        <div className={Style['container']}>
            <div className={Style['avilable-item-wrapper']}>
                <ListLayout name=" Product" list={availableProductList} setList={setAvailableProductList} getList={ProviderUserProductService.getList} getListLength={ProviderUserProductService.getListLength}>
                    <React.Fragment>
                        {(availableProductList as TypeUserProduct[]).map(product => <ProductInfoCardComponent key={product.uuid} product={product} handleProduct={handleProduct} isVisible={isVisibleProduct(product)} />)}
                    </React.Fragment>
                </ListLayout>
            </div>
            <div className={Style['seleted-item-wrapper']}>
                <div className={Style['row']}>
                    <Button handleClick={() => handleBillForPrint()}>Generate Bill</Button>
                </div>
                <div className={Style['list']}>
                    {selectedProductList.map(product => <ProductInfoCardComponent key={product.uuid} product={product} handleProduct={handleProduct} isVisible={true} />)}
                </div>
            </div>
        </div>
    </React.Fragment >
}

export default BillAddPage


import React from 'react'
import Style from './BillInfoCardComponent.module.scss'
import { TypeUserBill } from '../../types/providers/type-user-bill'
import Button from '../../atoms/button/Button'

interface Props {
    isVisible: boolean
    bill: TypeUserBill
    handleBill: (bill: TypeUserBill, num: number) => void
}

const BillInfoCardComponent = (props: Props) => {
    return <React.Fragment>
        <div className={Style['container']} style={{ display: props.isVisible ? 'flex' : 'none' }}>
            <div className={Style['short-icon']}>
                <p>GV</p>
            </div>
            <div className={Style['containt']}>
                <div className={Style['text-part']}>
                    <p className={Style['heading']}>{props.bill.name}</p>
                    <p className={Style['sub-heading']}>{props.bill.price}/- Only</p>
                </div>
                <Button handleClick={() => props.handleBill(props.bill, 1)}>Add</Button>
            </div>
        </div>
    </React.Fragment>
}
export default BillInfoCardComponent

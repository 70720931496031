
import React from 'react'
import Style from './StoreInfoCardComponent.module.scss'
import IconDot from '../../../assets/icons/icon_more_vert.svg'
import { TypeUserStore } from '../../types/providers/type-user-store'

interface Props {
    store: TypeUserStore
}

const StoreInfoCardComponent = (props: Props) => {
    return <React.Fragment>
        <div className={Style['container']}>
            <div className={Style['short-icon']}>
                <p>GV</p>
            </div>
            <div className={Style['containt']}>
                <div className={Style['text-part']}>
                    <p className={Style['heading']}>{props.store.name}</p>
                    <p className={Style['sub-heading']}>{props.store.address}</p>
                </div>
                <div className={Style['end-part']}>
                    <span>Open</span>
                    <img src={IconDot} alt="Three dot icons" />
                </div>
            </div>
        </div>
    </React.Fragment>
}
export default StoreInfoCardComponent

import React from 'react'
import Style from './NotFound404Component.module.scss'

const NotFound404Component = () => {
  return <React.Fragment>
    <div className={Style['container']}>
      Hello
    </div>
  </React.Fragment>
}

export default NotFound404Component

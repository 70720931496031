import React from 'react'
import Style from './NotFound404Page.module.scss'
import NotFound404Component from '../../components/not-found-404/NotFound404Component'

const NotFound404Page = () => {
  return <React.Fragment>
    <div className={Style['container']}>
      <NotFound404Component />
    </div>
  </React.Fragment>
}

export default NotFound404Page

import React from 'react'
import Style from './RightSectionClientListLayoutComponent.module.scss'
import IconAdd from '../../../assets/icons/icon_add-333333.svg'
import IconSearch from '../../../assets/icons/icon_search.svg'
import HelpIcon from '../../../assets/icons/icon_help.svg'
import ListIcon from '../../../assets/icons/icon_more.svg'
import { RegisterOptions, useForm } from 'react-hook-form'
import Button from '../../atoms/button/Button'
import Discard from '../../../assets/icons/icon_discard.svg'
import Apply from '../../../assets/icons/icon_apply.svg'
import FieldDate from '../../atoms/field-date/FieldDate'
import { useHistory } from 'react-router-dom'

enum FORM_CONTROL {
    Date = 'Date'
}

const RightSectionClientListLayoutComponent = () => {
    const history = useHistory()
    const form = useForm()

    const formControlName = (control: FORM_CONTROL) => {
        let option: RegisterOptions = {}

        return form.register(control, option)
    }

    const getFomrControlError = (Date: FORM_CONTROL) => {
        return form.formState.errors?.[Date]?.message as string
    }

    return <React.Fragment>
        <div className={Style['right-section']}>
            <h1>Details</h1>
            <p>Stores Information</p>
            <div className={Style['row-1']}>
                <div className={Style['icon-group']} onClick={() => history.push('add')}>
                    <div className={Style['icone']}>
                        <img src={IconAdd} alt='IconAdd' />
                    </div>
                    <h2>Add</h2>
                </div>
                <div className={Style['icon-group']}>
                    <div className={Style['icone']}>
                        <img src={IconSearch} alt='IconSearch' />
                    </div>
                    <h2>Find</h2>
                </div>
                <div className={Style['icon-group']}>
                    <div className={Style['icone']}>
                        <img src={HelpIcon} alt='HelpIcon' />
                    </div>
                    <h2>Help</h2>
                </div>
                <div className={Style['icon-group']}>
                    <div className={Style['icone']}>
                        <img src={ListIcon} alt='ListIcon' />
                    </div>
                    <h2>More</h2>
                </div>
            </div>
            <h2 className={Style['datefieldtext']}>Date Range</h2>
            <div className={Style['row-2']}>
                <FieldDate formContolName={formControlName(FORM_CONTROL.Date)} error={getFomrControlError(FORM_CONTROL.Date)} name={FORM_CONTROL.Date} label="Start date" />
                <FieldDate formContolName={formControlName(FORM_CONTROL.Date)} error={getFomrControlError(FORM_CONTROL.Date)} name={FORM_CONTROL.Date} label="End date" />
            </div>
            <div className={Style['row-3']}>
                <Button preIcon={Discard} handleClick={() => { }} >Discard</Button>
                <Button preIcon={Apply} handleClick={() => { }}>Apply</Button>
            </div>
        </div>
    </React.Fragment>
}

export default RightSectionClientListLayoutComponent
import React from "react"
import ReactDOM from 'react-dom'
import Style from "./AppModal.module.scss"
import { TYPE_MODAL } from "./types/type-app-modal"
import { helperDismissModal } from "./helpers/helper-location"
import { useHistory, useLocation } from "react-router-dom"
import LogoutModal from "./modals/logout/LogoutModal"

interface Props {
    modal: TYPE_MODAL
}

function AppModal(props: Props) {
    const history = useHistory()
    const location = useLocation()

    return ReactDOM.createPortal(
        <React.Fragment>
            <div className={Style['container']}>
                <button className={Style['floating-btn']} onClick={() => helperDismissModal(history, location, 'dismiss')}>Close</button>
                {props.modal === TYPE_MODAL.ModalClientImLogoutModal && <LogoutModal />}
            </div>
        </React.Fragment>,
        document.getElementById('root-modal') as HTMLElement
    )
}

export default AppModal
import React from 'react'
import Style from './HeaderComponent.module.scss'
import ImgLogoBmClient from '../../../assets/images/img_logo-bm_client.svg'
import IconApps from '../../../assets/icons/icon_apps.svg'
import IconAccountCircle from '../../../assets/icons/icon_account_circle.svg'
import { helperOpenModal } from '../../helpers/helper-location'
import { TYPE_MODAL } from '../../types/type-app-modal'
import { useHistory, useLocation } from 'react-router-dom'

const HeaderComponent = () => {
  const history = useHistory()
  const location = useLocation()

  const openModal = (modal: TYPE_MODAL) => {
    helperOpenModal(history, location, modal, {})
  }

  return <React.Fragment>
    <div className={Style['container']}>
      <div className={Style['left']}>
        <img src={IconApps} alt="IconApps" />
        <img src={ImgLogoBmClient} alt="ImgLogoBmClient" />
      </div>
      <div className={Style['right']}>
        <img src={IconAccountCircle} alt="icon_account_circle" onClick={() => openModal(TYPE_MODAL.ModalClientImLogoutModal)} />
      </div>
    </div>
  </React.Fragment>
}

export default HeaderComponent
